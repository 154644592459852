import React from 'react'

const IconSoundOn = () => (
  <svg
    width="147"
    height="147"
    viewBox="0 0 147 147"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="73.5" cy="73.5" r="73.5" fill="#474747" />
    <rect x="25" y="51" width="57" height="45" rx="5" fill="#DFDFDF" />
    <path
      d="M92.5 112.813C92.5 114.495 90.5512 115.426 89.2432 114.369L38.9858 73.7685C37.9759 72.9526 37.9989 71.4056 39.0328 70.6202L89.2901 32.4386C90.607 31.4382 92.5 32.3774 92.5 34.0312L92.5 112.813Z"
      fill="#DFDFDF"
    />
    <circle cx="86.5" cy="73.5" r="12.5" fill="#DFDFDF" />
    <path
      d="M102.195 53.8521C103.077 52.717 104.721 52.5034 105.77 53.4863C108.167 55.7317 110.157 58.3834 111.646 61.3254C113.528 65.0433 114.561 69.1321 114.673 73.2975C114.784 77.4628 113.97 81.601 112.29 85.4141C110.961 88.4315 109.115 91.1858 106.842 93.556C105.847 94.5936 104.193 94.4682 103.252 93.3818V93.3818C102.31 92.2954 102.441 90.6606 103.414 89.6025C105.116 87.7509 106.507 85.6277 107.526 83.315C108.896 80.2063 109.56 76.8325 109.469 73.4366C109.378 70.0407 108.535 66.7071 107.001 63.676C105.86 61.4211 104.358 59.3753 102.558 57.6173C101.53 56.6127 101.313 54.9873 102.195 53.8521V53.8521Z"
      fill="#DFDFDF"
    />
    <path
      d="M107.558 42.3918C108.382 41.3312 109.915 41.1342 110.927 42.0177C115.033 45.6029 118.416 49.9506 120.884 54.8277C123.719 60.4291 125.276 66.5895 125.444 72.8652C125.612 79.1408 124.386 85.3756 121.854 91.1205C119.65 96.1225 116.504 100.645 112.596 104.444C111.633 105.381 110.091 105.266 109.212 104.251V104.251C108.332 103.236 108.448 101.705 109.404 100.762C112.773 97.4382 115.489 93.503 117.403 89.1593C119.645 84.0725 120.73 78.5519 120.582 72.9952C120.433 67.4384 119.054 61.9837 116.544 57.024C114.401 52.7887 111.478 49.0043 107.937 45.8655C106.931 44.9746 106.734 43.4525 107.558 42.3918V42.3918Z"
      fill="#DFDFDF"
    />
  </svg>
)

export default IconSoundOn
