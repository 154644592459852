import React from 'react'
import ClickableContainer from './ClickableContainer'

const DormsBuilding: React.FC<{ setMapLocation: () => void }> = ({
  setMapLocation
}) => (
  <ClickableContainer id="dorms-building" onClick={setMapLocation}>
    <rect
      x="111.902"
      y="113.146"
      width="282.911"
      height="141.456"
      fill="#B24747"
    />
    <rect
      x="288.567"
      y="123.755"
      width="19.3733"
      height="24.9085"
      fill="#7F3333"
    />
    <rect
      x="291.335"
      y="126.522"
      width="13.838"
      height="8.30283"
      fill="#A6DFEA"
    />
    <rect
      x="291.335"
      y="137.593"
      width="13.838"
      height="8.30283"
      fill="#A6DFEA"
    />
    <rect
      x="285.8"
      y="143.128"
      width="24.9085"
      height="2.76761"
      fill="#FF9393"
    />
    <rect
      x="249.513"
      y="123.755"
      width="19.3733"
      height="24.9085"
      fill="#7F3333"
    />
    <rect
      x="252.281"
      y="126.522"
      width="13.838"
      height="8.30283"
      fill="#A6DFEA"
    />
    <rect
      x="252.281"
      y="137.593"
      width="13.838"
      height="8.30283"
      fill="#A6DFEA"
    />
    <rect
      x="246.746"
      y="143.128"
      width="24.9085"
      height="2.76761"
      fill="#FF9393"
    />
    <rect
      x="210.459"
      y="123.755"
      width="19.3733"
      height="24.9085"
      fill="#7F3333"
    />
    <rect
      x="213.227"
      y="126.522"
      width="13.838"
      height="8.30283"
      fill="#A6DFEA"
    />
    <rect
      x="213.227"
      y="137.593"
      width="13.838"
      height="8.30283"
      fill="#A6DFEA"
    />
    <rect
      x="207.692"
      y="143.128"
      width="24.9085"
      height="2.76761"
      fill="#FF9393"
    />
    <rect
      x="171.405"
      y="123.755"
      width="19.3733"
      height="24.9085"
      fill="#7F3333"
    />
    <rect
      x="174.173"
      y="126.522"
      width="13.838"
      height="8.30283"
      fill="#A6DFEA"
    />
    <rect
      x="174.173"
      y="137.593"
      width="13.838"
      height="8.30283"
      fill="#A6DFEA"
    />
    <rect
      x="168.638"
      y="143.128"
      width="24.9085"
      height="2.76761"
      fill="#FF9393"
    />
    <rect
      x="132.352"
      y="123.755"
      width="19.3733"
      height="24.9085"
      fill="#7F3333"
    />
    <rect
      x="135.119"
      y="126.522"
      width="13.838"
      height="8.30283"
      fill="#A6DFEA"
    />
    <rect
      x="135.119"
      y="137.593"
      width="13.838"
      height="8.30283"
      fill="#A6DFEA"
    />
    <rect
      x="129.584"
      y="143.128"
      width="24.9085"
      height="2.76761"
      fill="#FF9393"
    />
    <rect
      x="288.567"
      y="154.198"
      width="19.3733"
      height="24.9085"
      fill="#7F3333"
    />
    <rect
      x="291.335"
      y="156.966"
      width="13.838"
      height="8.30283"
      fill="#A6DFEA"
    />
    <rect
      x="291.335"
      y="168.036"
      width="13.838"
      height="8.30283"
      fill="#A6DFEA"
    />
    <rect
      x="285.8"
      y="173.572"
      width="24.9085"
      height="2.76761"
      fill="#FF9393"
    />
    <rect
      x="327.621"
      y="123.755"
      width="19.3733"
      height="24.9085"
      fill="#7F3333"
    />
    <rect
      x="330.389"
      y="126.522"
      width="13.838"
      height="8.30283"
      fill="#A6DFEA"
    />
    <rect
      x="330.389"
      y="137.593"
      width="13.838"
      height="8.30283"
      fill="#A6DFEA"
    />
    <rect
      x="324.854"
      y="143.128"
      width="24.9085"
      height="2.76761"
      fill="#FF9393"
    />
    <rect
      x="327.621"
      y="154.198"
      width="19.3733"
      height="24.9085"
      fill="#7F3333"
    />
    <rect
      x="330.389"
      y="156.966"
      width="13.838"
      height="8.30283"
      fill="#A6DFEA"
    />
    <rect
      x="330.389"
      y="168.036"
      width="13.838"
      height="8.30283"
      fill="#A6DFEA"
    />
    <rect
      x="324.854"
      y="173.572"
      width="24.9085"
      height="2.76761"
      fill="#FF9393"
    />
    <rect
      x="249.513"
      y="154.198"
      width="19.3733"
      height="24.9085"
      fill="#7F3333"
    />
    <rect
      x="252.281"
      y="156.966"
      width="13.838"
      height="8.30283"
      fill="#A6DFEA"
    />
    <rect
      x="252.281"
      y="168.036"
      width="13.838"
      height="8.30283"
      fill="#A6DFEA"
    />
    <rect
      x="246.746"
      y="173.572"
      width="24.9085"
      height="2.76761"
      fill="#FF9393"
    />
    <rect
      x="210.459"
      y="154.198"
      width="19.3733"
      height="24.9085"
      fill="#7F3333"
    />
    <rect
      x="213.227"
      y="156.966"
      width="13.838"
      height="8.30283"
      fill="#A6DFEA"
    />
    <rect
      x="213.227"
      y="168.036"
      width="13.838"
      height="8.30283"
      fill="#A6DFEA"
    />
    <rect
      x="207.692"
      y="173.572"
      width="24.9085"
      height="2.76761"
      fill="#FF9393"
    />
    <rect
      x="171.405"
      y="154.198"
      width="19.3733"
      height="24.9085"
      fill="#7F3333"
    />
    <rect
      x="174.173"
      y="156.966"
      width="13.838"
      height="8.30283"
      fill="#A6DFEA"
    />
    <rect
      x="174.173"
      y="168.036"
      width="13.838"
      height="8.30283"
      fill="#A6DFEA"
    />
    <rect
      x="168.638"
      y="173.572"
      width="24.9085"
      height="2.76761"
      fill="#FF9393"
    />
    <rect
      x="132.352"
      y="154.198"
      width="19.3733"
      height="24.9085"
      fill="#7F3333"
    />
    <rect
      x="135.119"
      y="156.966"
      width="13.838"
      height="8.30283"
      fill="#A6DFEA"
    />
    <rect
      x="135.119"
      y="168.036"
      width="13.838"
      height="8.30283"
      fill="#A6DFEA"
    />
    <rect
      x="129.584"
      y="173.572"
      width="24.9085"
      height="2.76761"
      fill="#FF9393"
    />
    <rect
      x="288.567"
      y="185.619"
      width="19.3733"
      height="24.9085"
      fill="#7F3333"
    />
    <rect
      x="291.335"
      y="188.387"
      width="13.838"
      height="8.30283"
      fill="#A6DFEA"
    />
    <rect
      x="291.335"
      y="199.457"
      width="13.838"
      height="8.30283"
      fill="#A6DFEA"
    />
    <rect
      x="285.8"
      y="204.992"
      width="24.9085"
      height="2.76761"
      fill="#FF9393"
    />
    <rect
      x="327.621"
      y="185.619"
      width="19.3733"
      height="24.9085"
      fill="#7F3333"
    />
    <rect
      x="330.389"
      y="188.387"
      width="13.838"
      height="8.30283"
      fill="#A6DFEA"
    />
    <rect
      x="330.389"
      y="199.457"
      width="13.838"
      height="8.30283"
      fill="#A6DFEA"
    />
    <rect
      x="324.854"
      y="204.992"
      width="24.9085"
      height="2.76761"
      fill="#FF9393"
    />
    <rect
      x="249.513"
      y="185.619"
      width="19.3733"
      height="24.9085"
      fill="#7F3333"
    />
    <rect
      x="252.281"
      y="188.387"
      width="13.838"
      height="8.30283"
      fill="#A6DFEA"
    />
    <rect
      x="252.281"
      y="199.457"
      width="13.838"
      height="8.30283"
      fill="#A6DFEA"
    />
    <rect
      x="246.746"
      y="204.992"
      width="24.9085"
      height="2.76761"
      fill="#FF9393"
    />
    <rect
      x="210.152"
      y="183.873"
      width="19.3733"
      height="25.482"
      fill="#7F3333"
    />
    <rect
      x="235.676"
      y="229.846"
      width="17.6819"
      height="24.7547"
      fill="#7F3333"
    />
    <rect
      x="253.358"
      y="229.846"
      width="3.53639"
      height="24.7547"
      fill="#4C1E1E"
    />
    <rect
      x="232.139"
      y="229.846"
      width="3.53639"
      height="24.7547"
      fill="#4C1E1E"
    />
    <rect
      x="256.894"
      y="226.31"
      width="3.53639"
      height="24.7547"
      transform="rotate(90 256.894 226.31)"
      fill="#4C1E1E"
    />
    <rect
      x="212.92"
      y="186.705"
      width="13.838"
      height="8.49402"
      fill="#A6DFEA"
    />
    <rect
      x="212.92"
      y="198.03"
      width="13.838"
      height="8.49402"
      fill="#A6DFEA"
    />
    <rect
      x="207.385"
      y="203.693"
      width="24.9085"
      height="2.83134"
      fill="#FF9393"
    />
    <rect
      x="171.405"
      y="185.619"
      width="19.3733"
      height="24.9085"
      fill="#7F3333"
    />
    <rect
      x="174.173"
      y="188.387"
      width="13.838"
      height="8.30283"
      fill="#A6DFEA"
    />
    <rect
      x="174.173"
      y="199.457"
      width="13.838"
      height="8.30283"
      fill="#A6DFEA"
    />
    <rect
      x="168.638"
      y="204.992"
      width="24.9085"
      height="2.76761"
      fill="#FF9393"
    />
    <rect
      x="132.352"
      y="185.619"
      width="19.3733"
      height="24.9085"
      fill="#7F3333"
    />
    <rect
      x="135.119"
      y="188.387"
      width="13.838"
      height="8.30283"
      fill="#A6DFEA"
    />
    <rect
      x="135.119"
      y="199.457"
      width="13.838"
      height="8.30283"
      fill="#A6DFEA"
    />
    <rect
      x="129.584"
      y="204.992"
      width="24.9085"
      height="2.76761"
      fill="#FF9393"
    />
    <rect
      x="362.986"
      y="169.728"
      width="7.07278"
      height="3.53639"
      fill="#7F3333"
    />
    <rect
      x="377.131"
      y="134.364"
      width="7.07278"
      height="3.53639"
      fill="#7F3333"
    />
    <rect
      x="355.913"
      y="113.146"
      width="7.07278"
      height="3.53639"
      fill="#7F3333"
    />
    <rect
      x="122.511"
      y="190.946"
      width="7.07278"
      height="3.53639"
      fill="#7F3333"
    />
    <rect
      x="274.576"
      y="120.218"
      width="7.07278"
      height="3.53639"
      fill="#7F3333"
    />
    <rect
      x="274.576"
      y="187.41"
      width="7.07278"
      height="3.53639"
      fill="#7F3333"
    />
    <rect
      x="235.676"
      y="215.701"
      width="7.07278"
      height="3.53639"
      fill="#7F3333"
    />
    <rect
      x="232.139"
      y="183.873"
      width="7.07278"
      height="3.53639"
      fill="#7F3333"
    />
    <rect
      x="157.875"
      y="222.774"
      width="7.07278"
      height="3.53639"
      fill="#7F3333"
    />
    <rect
      x="118.975"
      y="134.364"
      width="7.07278"
      height="3.53639"
      fill="#7F3333"
    />
    <rect
      x="196.775"
      y="159.119"
      width="7.07278"
      height="3.53639"
      fill="#7F3333"
    />
    <rect
      x="111.902"
      y="247.528"
      width="7.07278"
      height="3.53639"
      fill="#7F3333"
    />
    <rect
      x="370.058"
      y="226.31"
      width="7.07278"
      height="3.53639"
      fill="#7F3333"
    />
    <rect
      x="324.085"
      y="229.846"
      width="7.07278"
      height="3.53639"
      fill="#7F3333"
    />
    <rect
      x="196.775"
      y="233.383"
      width="7.07278"
      height="3.53639"
      fill="#7F3333"
    />
    <circle cx="251.589" cy="242.224" r="1.76819" fill="#F6D475" />
    <rect
      x="140.193"
      y="102.536"
      width="7.07278"
      height="10.6092"
      fill="#797979"
    />
    <rect x="341.767" y="99" width="31.8275" height="10.6092" fill="#797979" />
    <rect
      x="108.366"
      y="106.073"
      width="289.984"
      height="7.07278"
      fill="#7F3333"
    />
  </ClickableContainer>
)

export default DormsBuilding
