import React from 'react'
import styled from '../../utils/styled'

const Container = styled.g`
  &:hover,
  &:active,
  &:focus {
    cursor: pointer;
  }
`

const RitchieHallIcon: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <Container onClick={onClick}>
    <rect
      x="542.862"
      y="528.621"
      width="119.391"
      height="59.4253"
      fill="#FBECC3"
    />
    <path d="M550.155 560.764L559.609 554.552" stroke="black" />
    <rect
      x="558.448"
      y="496.126"
      width="12.5862"
      height="18.5287"
      fill="#D2EFF4"
      stroke="#B2B2B2"
      strokeWidth="2"
    />
    <rect
      x="619.558"
      y="519.907"
      width="7.97968"
      height="17.8042"
      fill="#14697A"
      stroke="#FAFAFA"
    />
    <rect
      x="628.538"
      y="519.907"
      width="7.97961"
      height="17.8042"
      fill="#14697A"
      stroke="#FAFAFA"
    />
    <rect
      x="626.453"
      y="527.324"
      width="1.0564"
      height="1.97943"
      fill="#FAFAFA"
    />
    <rect
      x="628.566"
      y="527.324"
      width="1.0564"
      height="1.97943"
      fill="#FAFAFA"
    />
    <rect
      x="617.474"
      y="519.407"
      width="1.58459"
      height="18.8042"
      fill="#797979"
    />
    <rect
      x="637.017"
      y="519.407"
      width="1.58466"
      height="18.8042"
      fill="#797979"
    />
    <rect
      x="638.602"
      y="517.922"
      width="1.4845"
      height="21.1285"
      transform="rotate(90 638.602 517.922)"
      fill="#797979"
    />
    <rect
      x="550.345"
      y="540.425"
      width="9.34483"
      height="36.8966"
      fill="#D2EFF4"
      stroke="#797979"
      strokeWidth="2"
    />
    <path
      opacity="0.5"
      d="M559.609 554.822L550.425 562.115V564.276L559.609 557.253V554.822Z"
      fill="#F3F3F3"
    />
    <path
      opacity="0.2"
      d="M559.609 562.115L550.425 569.408V571.569L559.609 564.546V562.115Z"
      fill="#F3F3F3"
    />
    <rect
      x="570.333"
      y="540.425"
      width="9.34483"
      height="36.8966"
      fill="#D2EFF4"
      stroke="#797979"
      strokeWidth="2"
    />
    <path
      opacity="0.5"
      d="M579.598 554.822L570.414 562.115V564.276L579.598 557.253V554.822Z"
      fill="#F3F3F3"
    />
    <path
      opacity="0.2"
      d="M579.598 562.115L570.414 569.408V571.569L579.598 564.546V562.115Z"
      fill="#F3F3F3"
    />
    <rect
      x="590.322"
      y="540.425"
      width="9.34483"
      height="36.8966"
      fill="#D2EFF4"
      stroke="#797979"
      strokeWidth="2"
    />
    <path
      opacity="0.5"
      d="M599.586 554.822L590.402 562.115V564.276L599.586 557.253V554.822Z"
      fill="#F3F3F3"
    />
    <path
      opacity="0.2"
      d="M599.586 562.115L590.402 569.408V571.569L599.586 564.546V562.115Z"
      fill="#F3F3F3"
    />
    <rect
      x="610.31"
      y="540.425"
      width="9.34483"
      height="36.8966"
      fill="#D2EFF4"
      stroke="#797979"
      strokeWidth="2"
    />
    <path
      opacity="0.5"
      d="M619.575 554.822L610.391 562.115V564.276L619.575 557.253V554.822Z"
      fill="#F3F3F3"
    />
    <path
      opacity="0.2"
      d="M619.575 562.115L610.391 569.408V571.569L619.575 564.546V562.115Z"
      fill="#F3F3F3"
    />
    <rect
      x="539.621"
      y="487.563"
      width="124.793"
      height="50.7816"
      fill="#FFC06E"
    />
    <rect
      x="582.759"
      y="502.609"
      width="10.4253"
      height="12.046"
      fill="#D2EFF4"
      stroke="#797979"
      strokeWidth="2"
    />
    <rect
      x="560.609"
      y="502.609"
      width="10.4253"
      height="12.046"
      fill="#D2EFF4"
      stroke="#797979"
      strokeWidth="2"
    />
    <rect
      x="604.368"
      y="502.609"
      width="10.4253"
      height="12.046"
      fill="#D2EFF4"
      stroke="#797979"
      strokeWidth="2"
    />
    <rect
      x="626.517"
      y="502.609"
      width="10.4253"
      height="12.046"
      fill="#D2EFF4"
      stroke="#797979"
      strokeWidth="2"
    />
    <ellipse
      cx="543.132"
      cy="587.573"
      rx="2.43103"
      ry="1.01293"
      fill="#A8DBBD"
    />
    <ellipse
      cx="545.833"
      cy="586.898"
      rx="2.43103"
      ry="1.01293"
      fill="#A8DBBD"
    />
    <ellipse
      cx="540.971"
      cy="586.898"
      rx="2.43103"
      ry="1.01293"
      fill="#A8DBBD"
    />
    <ellipse
      cx="553.397"
      cy="587.573"
      rx="2.43103"
      ry="1.01293"
      fill="#A8DBBD"
    />
    <ellipse
      cx="556.098"
      cy="586.898"
      rx="2.43103"
      ry="1.01293"
      fill="#A8DBBD"
    />
    <ellipse
      cx="551.236"
      cy="586.898"
      rx="2.43103"
      ry="1.01293"
      fill="#A8DBBD"
    />
    <ellipse
      cx="563.661"
      cy="587.573"
      rx="2.43103"
      ry="1.01293"
      fill="#A8DBBD"
    />
    <ellipse
      cx="566.362"
      cy="586.898"
      rx="2.43103"
      ry="1.01293"
      fill="#A8DBBD"
    />
    <ellipse cx="561.5" cy="586.898" rx="2.43103" ry="1.01293" fill="#A8DBBD" />
    <ellipse
      cx="573.925"
      cy="587.573"
      rx="2.43103"
      ry="1.01293"
      fill="#A8DBBD"
    />
    <ellipse
      cx="576.626"
      cy="586.898"
      rx="2.43103"
      ry="1.01293"
      fill="#A8DBBD"
    />
    <ellipse
      cx="571.764"
      cy="586.898"
      rx="2.43103"
      ry="1.01293"
      fill="#A8DBBD"
    />
    <ellipse
      cx="584.19"
      cy="587.573"
      rx="2.43103"
      ry="1.01293"
      fill="#A8DBBD"
    />
    <ellipse
      cx="586.891"
      cy="586.898"
      rx="2.43103"
      ry="1.01293"
      fill="#A8DBBD"
    />
    <ellipse
      cx="582.029"
      cy="586.898"
      rx="2.43103"
      ry="1.01293"
      fill="#A8DBBD"
    />
    <ellipse
      cx="594.454"
      cy="587.573"
      rx="2.43103"
      ry="1.01293"
      fill="#A8DBBD"
    />
    <ellipse
      cx="597.155"
      cy="586.898"
      rx="2.43103"
      ry="1.01293"
      fill="#A8DBBD"
    />
    <ellipse
      cx="592.293"
      cy="586.898"
      rx="2.43103"
      ry="1.01293"
      fill="#A8DBBD"
    />
    <rect x="538" y="586.965" width="62.1264" height="3.24138" fill="#99631D" />
    <rect
      x="546.103"
      y="518.897"
      width="4.32184"
      height="1.62069"
      fill="#CC8427"
    />
    <rect x="585" y="531.322" width="4.32184" height="1.62069" fill="#CC8427" />
    <rect
      x="540.701"
      y="487.563"
      width="4.32184"
      height="1.62069"
      fill="#CC8427"
    />
    <rect
      x="616.333"
      y="525.379"
      width="4.32184"
      height="1.62069"
      fill="#CC8427"
    />
    <rect
      x="597.425"
      y="491.345"
      width="4.32184"
      height="1.62069"
      fill="#CC8427"
    />
    <rect
      x="637.943"
      y="490.805"
      width="4.32184"
      height="1.62069"
      fill="#CC8427"
    />
    <rect
      x="647.126"
      y="534.023"
      width="4.32184"
      height="1.62069"
      fill="#CC8427"
    />
    <rect
      x="660.092"
      y="507.552"
      width="4.32184"
      height="1.62069"
      fill="#CC8427"
    />
    <rect x="620.655" y="480" width="17.2874" height="7.56322" fill="#C4C4C4" />
    <rect
      x="624.437"
      y="514.575"
      width="14.5862"
      height="2.16092"
      fill="#B2B2B2"
    />
    <rect
      x="602.287"
      y="514.575"
      width="14.5862"
      height="2.16092"
      fill="#B2B2B2"
    />
    <rect
      x="580.678"
      y="514.575"
      width="14.5862"
      height="2.16092"
      fill="#B2B2B2"
    />
    <rect
      x="558.529"
      y="514.575"
      width="14.5862"
      height="2.16092"
      fill="#B2B2B2"
    />
    <rect
      x="633.6"
      y="562.6"
      width="10.9"
      height="25.6"
      fill="#14697A"
      stroke="#FAFAFA"
    />
    <rect
      x="645.5"
      y="562.6"
      width="10.9"
      height="25.6"
      fill="#14697A"
      stroke="#FAFAFA"
    />
    <rect x="642.9" y="573.3" width="1.4" height="2.8" fill="#FAFAFA" />
    <rect x="645.7" y="573.3" width="1.4" height="2.8" fill="#FAFAFA" />
    <rect x="631" y="562.1" width="2.1" height="26.6" fill="#797979" />
    <rect x="656.9" y="562.1" width="2.1" height="26.6" fill="#797979" />
    <rect
      x="659"
      y="560"
      width="2.1"
      height="28"
      transform="rotate(90 659 560)"
      fill="#797979"
    />
  </Container>
)

export default RitchieHallIcon
